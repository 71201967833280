import React from 'react';

// Generates a custom select options in html
//function GenerateSelect(props){
class CustomClassSelect extends React.Component {

    constructor(props){
        super(props);
        //this.data = this.props.data;

        //Define class states
        //Value = select option value
        this.state = {
            value : '',
            data : this.props.data
        }

        this.handleChange = this.handleChange.bind(this);

    }

    //Works when a new option is selected
    //changing the state value
    handleChange(event) {

        //Sets the new state value
        //const temp = event.target.value;
        this.setState({value: event.target.value});

        const i = event.target.options.selectedIndex;
        const gid = event.target.options[i].getAttribute('gid');

        //Calls the parent handler
        this.props.secondHandler(event.target, gid);
      
    }

    //Generate de options from props.data
    generateOptions() {

        var op = this.state.data.selecOptions.map((dat) => 
            <option 
                key={dat.groupID} 
                gid={dat.groupID}
                value={dat.value}
            > 
                {dat.label}
            </option>
        );
        return op
    }

    render() {

        return(
            <div class="SelectStyle">
            <select
                class="selectpicker show-tick"
                id={this.props.id} 
                value={this.state.value} 
                onChange={this.handleChange}
            >
                {this.generateOptions()}     
            </select>
            </div>
            
        );
       

    }
}

export default CustomClassSelect;