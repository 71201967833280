import React from 'react';


import genderSelect,
{
    locationSelect,
    groupsSelect,
    groupsSelect2,
    groupSelectOptions,
    tryDayPicker,
    selectDayButton,
    bornDate
}
    from './supFuncs.js';

import genderData,
{
    locationData,
    apiGetClases,
    conditions
}
    from './formOptions.js';
import { eachHourOfInterval } from 'date-fns';



//Class for the first part of the form
class PartOne extends React.Component {

    constructor(props) {
        super(props)

        /* Define state
            locationOK = true -> shows the second select
        */
        this.state = {
            gender: genderData.selecOptions[0].value,
            location: locationData.selecOptions[0].value,
            age: false,
            groupIndex: 0,
            loaded: false,
            clases: [],
            days: new Set(),
            daysNames: new Set(),
            groupID: 3, //Definir como variable el id inicial
            startDay: '',
            bornDate: '',
            allowDays: new Set(),
            resetCalendar: false,
            contador: 0

        }


        this.genderHandler = this.genderHandler.bind(this);
        this.locationHandler = this.locationHandler.bind(this);
        this.ageHandler = this.ageHandler.bind(this);
        this.groupIndexHandler = this.groupIndexHandler.bind(this);
        this.loadedHandler = this.loadedHandler.bind(this);
        this.daysHandler = this.daysHandler.bind(this);
        this.daysSecondHandler = this.daysSecondHandler.bind(this);
        this.inQueueHandler = this.inQueueHandler.bind(this);
        this.allowDaysHandler = this.allowDaysHandler.bind(this);
        this.startDayHandler = this.startDayHandler.bind(this);
        this.bornDateHandler = this.bornDateHandler.bind(this);
        this.resetHandler = this.resetHandler.bind(this)



    }


    componentDidMount() {

        const api = apiGetClases

        var myHeaders = new Headers();

        var miInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        //Fetch the query
        fetch(api, miInit)
            .then(response => response.json())
            .then(myJson => this.setState({
                loaded: true,
                clases: myJson
            })
            ).catch(err => {
                console.error(err);
            });

    }


    //Handler for the locationOK state
    // and the groupOK state
    genderHandler(condition) {

        this.setState({ gender: condition })
        this.groupIndexHandler({
            gender: condition,
            location: this.state.location
        })
        //Calls the parent handler
        this.props.parentHandler({
            key: 'gender',
            value: condition
        })
        this.resetDays()
    }

    //Hander for de groupOK state
    locationHandler(condition) {

        this.setState({ location: condition })
        this.groupIndexHandler({
            gender: this.state.gender,
            location: condition
        })
        //Calls the parent handler
        this.props.parentHandler({
            key: 'location',
            value: condition
        })

        this.resetDays()

    }


    //Handler for de age state
    ageHandler(option, gid) {
        this.setState({ age: option.value })
        this.setState({ groupID: Number(gid) })

        //Calls the parent handler
        this.props.parentHandler({
            key: 'age',
            value: option.value
        })
        this.props.parentHandler({
            key: 'groupID',
            value: Number(gid)
        })

        this.resetDays()
    }

    //Handler for starting day
    startDayHandler(condition) {

        let date = ''
        //Checks condition is a date
        if (condition instanceof Date)
            date =
                condition.getDate()
                + '/' +
                (condition.getMonth() + 1)
                + '/' +
                condition.getFullYear()
                ;

        this.setState({ startDay: condition })

        //Calls the parent handler
        this.props.parentHandler({
            key: 'startDay',
            value: date
        })

        this.setState({
            resetCalendar: false
        })
    }

    bornDateHandler(condition) {

        let date = ''
        //Checks condition is a date
        if (condition instanceof Date)
            date =
                condition.getDate()
                + '/' +
                (condition.getMonth() + 1)
                + '/' +
                condition.getFullYear()
                ;

        this.setState({ bornDate: condition })

        //Calls the parent handler
        this.props.parentHandler({
            key: 'bornDate',
            value: date
        })
    }

    //Handler for the day selection buttons
    daysHandler(val) {

        var temp = this.state.days;

        //Updates the set
        if (val != null && temp.has(val))
            temp.delete(val)
        else
            temp.add(val)

        //Sets the state
        this.setState({
            days: temp
        })

        //Creates the string and calls the
        //parentHanlder
        var dayStr = '';
        for (var item of temp.values())
            if (dayStr === '')
                dayStr = item
            else
                dayStr = dayStr + ', ' + item

        dayStr = '[' + dayStr + ']'

        //Call the parent handler
        this.props.parentHandler({
            key: 'days',
            value: dayStr
        })

        this.props.parentHandler({
            key: 'resetDay',
            value: false
        })



    }

    //Handler for the day selection buttons
    daysSecondHandler(val) {

        var temp = this.state.daysNames;

        //Updates the set
        if (val != null && temp.has(val))
            temp.delete(val)
        else
            temp.add(val)

        //Sets the state
        this.setState({
            daysNames: temp
        })

        //Creates the string and calls the
        //parentHanlder
        var dayStr = '';
        for (var item of temp.values())
            if (dayStr === '')
                dayStr = item
            else
                dayStr = dayStr + ', ' + item

        //dayStr = '['+dayStr+']'

        //Call the parent handler
        this.props.parentHandler({
            key: 'daysNames',
            value: dayStr
        })

    }

    inQueueHandler(val) {
        this.props.parentHandler({
            key: 'inQueue',
            value: val
        })
    }

    //Handler that controls the allowed days for the date picker
    allowDaysHandler(val) {
        let temp = this.state.allowDays

        //Updates the set
        if (val != null && temp.has(val))
            temp.delete(val)
        else
            temp.add(val)

        //Set the state allowed days for the datePicker
        this.setState({
            allowDays: temp
        })

    }

    resetHandler() {
        this.setState({
            resetCalendar: false
        })
    }

    //Reset the days state and calls the parentHandler
    resetDays() {

        //Sets the state
        this.setState({
            days: new Set()
        })

        this.setState({
            daysNames: new Set()
        })

        this.setState({
            cambia: this.state.cambia + 1
        })

        //Call the parent handler
        this.props.parentHandler({
            key: 'days',
            value: '[]'
        })

        this.props.parentHandler({
            key: 'daysNames',
            value: ''
        })

        //Sets the parent inQueue state
        this.props.parentHandler({
            key: 'inQueue',
            value: false

        })

        this.props.parentHandler({
            key: 'resetDay',
            value: false
        })

        this.props.parentHandler({
            key: 'startDay',
            value: ''
        })

        //Sets the state for the allowd days in the 
        //datePicker
        this.setState({
            allowDays: new Set()
        })

        //Reset startDay
        this.setState({
            startDay: ''
        })

    }

    //Handlers for the group state
    groupIndexHandler(condition) {


        var index = 0   //Index for the group select
        var gid = 3     //Index for the classes buttons

        if(condition.gender === conditions.gaf )
            if(condition.location === conditions.inef){
                index = 0
                gid = 3
            } else {    
                index = 2   // GAF NAVE
                gid = 1     
            }
        else 
            if(condition.location === conditions.inef) {
                index = 1
                gid = 3
            } else {        // GAM NAVE
                index = 3
                gid = 1
            }

        this.setState({
            //Controls the selected index for group select
            groupIndex: index,
            //Info that will be send in the form
            groupID: gid
        }
        )
        //Call the parent handler
        this.props.parentHandler({
            key: 'groupID',
            value: gid
        })

    }


    loadedHandler() {
        this.setState({ loaded: true })
    }


    render() {

        return [
            <h1 class="mb-5" >
               Inscripción 2023-2024
            </h1>,
            genderSelect(
                this.genderHandler
            ),
            locationSelect(this.locationHandler),
            groupSelectOptions(
                    this.state.groupIndex,
                    this.props.groups,
                    this.ageHandler,
            ),
            <h4 className='mb-3'>Configura tu horario</h4>,
            // groupsSelect2(
            //     this.state.groupIndex,
            //     this.props.groups,
            //     this.ageHandler,
            //     // this.state.gender,
            //     // this.state.location

            // ),
            selectDayButton(
                this.state.loaded,
                this.state.clases,
                this.state.groupID,
                this.daysHandler,
                this.daysSecondHandler,
                this.allowDaysHandler,
                this.inQueueHandler,
                this.state.gender,
                this.state.location,
            ),
            // tryDayPicker(
            //     this.state.allowDays,
            //     this.startDayHandler,
            //     this.state.gender,
            //     this.state.location,
            //     false
            // ),
            bornDate(
                this.bornDateHandler,
                this.state.gender,
                this.state.location
            )



        ];

    }

}


export default PartOne;