import React from 'react';
import MyInputText from './myInputText';
import partTwoElements from './formOptions';

class PartTwo extends React.Component{

    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this)
 
    }

    handleChange(data){
        
        this.props.parentHandler(data)
    }
    
    render (){

        
        return [
            <span key="p2keyspan" className="contact100-form-title">
                Datos del niñ@
            </span>,
            <div key="p2div01">
                <MyInputText 
                    label={'Nombre'}
                    id={'name'}
                    key={'name'} 
                    parentHandler={this.handleChange}
                />
            </div>,
            <div key="p2div02">
                <MyInputText 
                    label={'Apellidos'}
                    id={'surname'}
                    key={'surname'} 
                    parentHandler={this.handleChange}
                />
            </div>,
            <div key="p2div03">
                <MyInputText 
                    label={'email'}
                    id={'email'}
                    key={'email'} 
                    parentHandler={this.handleChange}
                />
            </div>,
            <div key="p2div04">
                <MyInputText 
                    label={'Teléfono'}
                    id={'phone'}
                    key={'phone'} 
                    parentHandler={this.handleChange}
                />
            </div>
        ]

    }

}

export default PartTwo;