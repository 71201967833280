
const interval = 120;


// const apiGetClases = 'http://localhost/apireservas/clase/read.php'
// const apiGetGrupos = 'http://localhost/apireservas/grupo/read.php'
// const apiPostReserva = 'http://localhost/apireservas/reserva/create.php'

const apiGetClases = 'https://api.terrasportsacademy.com/clase/read.php'
const apiGetGrupos = 'https://api.terrasportsacademy.com/grupo/read.php'
const apiPostReserva = 'https://api.terrasportsacademy.com/reserva/create.php'

const conditions = {
    gaf: 'GAF',
    gam: 'GAM',
    inef: 'INEF',
    arteixo: 'A GRELA'
};

//Gender options 
//Define values for gender select
const genderData = {
    id:"genSel",
    value:'',
    change:'',
    selecOptions: [
        
        { value: conditions.gaf, label: 'Femenina'},
        { value: conditions.gam, label: 'Masculina'}    
    ]
};

//Location options
//Define values for locations select
const locationData = {
    id:"locSel",
    value:'',
    change:'',
    selecOptions: [
        {         
            value: conditions.inef, 
            label: 'Facultad de las Ciencias del Deporte'
            +' y la salud (Bastiagueiro)'
        },
        { 
            value: conditions.arteixo, 
            label: 'Terra Sports Academy (Poligono de A Grela)'
        }    
    ]
};

//Gropus options
const inefGAF = {
    id:"inefGAF",
    value:'',
    change:'',
    selecOptions: [
        { value: '3-4', label: '3 a 4 años', groupID: 1},
        { value: '5-7', label: '5 a 7 años', groupID: 4},
        { value: '8-10', label: '8 a 10 años', groupID: 5},
        { value: '+11', label: '11 o más años', groupID: 6}     
    ]
};

const inefGAM = {
    id:"inefGAM",
    value:'',
    change:'',
    selecOptions: [
        { value: '3-4', label: '3 a 4 años', groupID: 1},
        { value: '5-9', label: '5 a 10 años', groupID: 7}      
    ]
};

const gymGAF = {
    id:"gymGAF",
    value:'',
    change:'',
    selecOptions: [
        { value: '3-4', label: '3 a 4 años', groupID: 3},
        { value: '5-7', label: '5 a 7 años', groupID: 8},
        { value: '8-10', label: '8 a 10 años', groupID: 9},
        { value: '+11', label: '12 o más años', groupID: 10}     
    ]
};

const gymGAM = {
    id:"gymGAM",
    value:'',
    change:'',
    selecOptions: [
        { value: '3-4', label: '3 a 4 años', groupID: 3},
        { value: '5-9', label: '5 a 7 años', groupID: 11}      
    ]
};

const partTwoElements = [
    { id:'InputName', key:'name', 
        label: 'Nombre', type:'inputText'},
    { id:'InputSurname', key:'surname', 
        label: 'Apellidos',  type:'inputText'},
    { id:'InputEmail', key:'email', 
        label: 'Email', type:'inputText'},
    { id:'InputPhone', key:'phone', 
        label: 'Teléfono',  type:'inputText'}
]


//Temporal eliminar por conexion a DB

export default genderData;
//export {locationData};
//export {inefGAF};
//export {inefGAM};
//export {gymGAF};
//export {gymGAM};
export {
    inefGAF,
    inefGAM,
    gymGAF,
    gymGAM,
    locationData,
    conditions, 
    interval,
    partTwoElements,
    apiGetClases,
    apiGetGrupos,
    apiPostReserva
};
