import React from 'react';

//Import custom files
import CustomSelect from './customSelect.js';
import CustomClassSelect from './customClassSelect.js';
import CustomClassSelect2 from './customClassSelect2';
import MyCalendar from './myCalendar.js';
import eachDayOfInterval from "date-fns/eachDayOfInterval"
import DayButton from './buttonDay';
import genderData,
{
    locationData,
    interval,
    gymGAF,
    gymGAM,
    inefGAF,
    inefGAM,
}
    from './formOptions.js';

//Render suport functions


//Generates select 1
function genderSelect(myHandler) {
    return (
        <div id="genderSelect" key="gs">
            <CustomSelect
                id='gender'
                data={genderData}
                secondHandler={myHandler}
            />
        </div>
    );
}


//Generates de location select
function locationSelect(myHandler) {

    return (
        <div id="locationSelect" key="locationSelectp1p">
            <CustomSelect
                data={locationData}
                secondHandler={myHandler}
            />
        </div>

    );

}

function groupSelectOptions(index, groups, handler) {

    let cat = 'gaf';
    let location = 'inef';

    switch (index) {
        case 0:
            cat = 'gaf'
            location = 'inef'
            break;
        case 1:
            cat = 'gam'
            location = 'inef'
            break;
        case 2:
            cat = 'gaf'
            location = 'gimnasio'
            break;
        case 3:
            cat = 'gam'
            location = 'gimnasio'
            break;
        default: break;
    }

    const data = groups.filter(n => n.instalacion == location)
        .filter(n => n.cat == cat || n.cat == "mixto");

    return (



        <div id="locationSelect" key="locSelp1p">
            <CustomClassSelect2
                id={'groupSelect' + index}
                data={data}
                secondHandler={handler}
                index={index}
            />
        </div>


    );


}

//Generates the groups select
function groupsSelect2(index, groups, myHandler, gent, loct) {

    let res = ''
    const data = []
    let condition = false
    let gen = 'gaf'
    let loc = 'inef'

    /* index defines location select index
    gid defines de group id index
    pair index / gid
    0 -> gaf inef then set group 1
    1 -> gam inef then set group 1
    2 -> gaf gym
    3 -> gam gym
       */

    switch (index) {
        case 0:
            gen = 'gaf'
            loc = 'inef'
            break;
        case 1:
            gen = 'gam'
            loc = 'inef'
            break;
        case 2:
            gen = 'gaf'
            loc = 'gimnasio'
            break;
        case 3:
            gen = 'gam'
            loc = 'gimnasio'
            break;
    }

    //Generates the array data
    for (const i in groups) {

        if (groups[i].cat === gen && groups[i].instalacion === loc)
            data.push(groups[i])
        else if (groups[i].cat === 'mixto' &&
            groups[i].instalacion === loc)
            data.push(groups[i])
    }

    return (
        <div key='gSelect2' id="locationSelect">
            <CustomClassSelect2
                id={'groupSelect'}
                data={data}
                secondHandler={myHandler}
            />
        </div>
    )

}


//Generates the groups select
function groupsSelect(index, myHandler) {

    let res = ''


    const gafInef = () => {
        return <CustomClassSelect
            data={inefGAF}
            secondHandler={myHandler}
        />
    }

    const gamInef = () => {
        return <CustomClassSelect
            data={inefGAM}
            secondHandler={myHandler}
        />
    }

    const gafGym = () => {
        return <CustomClassSelect
            data={gymGAF}
            secondHandler={myHandler}
        />
    }

    const gamGym = () => {
        return <CustomClassSelect
            data={gymGAM}
            secondHandler={myHandler}
        />
    }
    /* index defines location select index
       gid defines de group id index
       pair index / gid
       0 -> gaf inef then set group 1
       1 -> gam inef then set group 1

    
    switch(index){
        case 0:
            res = gafInef()
        break;
        case 1:
            res = gamInef()
        break;
        case 2:
            res = gafGym()
            break;
        case 3:
            res = gamGym()
        break;
    }

    return( res )*/


    if (index === 0 || index === 2) {
        return (
            <div id="locationSelect">
                {gafInef()}
            </div>
        );
    } else {
        return (

            gamInef()

        );
    }

}

//Generates de buttons to select the trainings days
//in a row
function selectDayButton(loaded, list, gid, handler,
    secondHandler, allowDaysHandler,
    inQueueHandler, gen, loc) {

    var items = [];

    let label = '';

    if (loaded && list.clases.length > 0) {

        for (const i in list.clases) {

            if (list.clases[i].idGrupo === gid) {

                let max = list.clases[i].max
                let res = list.clases[i].reserved
                let free = 0
                let dayFree = true

                //Check values
                if (Number.isInteger(max) && Number.isInteger(res)) {

                    //Calulate free places
                    free = max - res;
                    label = '';


                    if(free <= 0){
                        label = '- Lista de espera '; 
                        dayFree = false; 
                    } else if (free < 3) {
                        label = '- Últimas plazas ';
                        dayFree = true;
                    } else {
                        label = '';
                    }

                }

                items.push(
                    <DayButton
                        value={
                            list.clases[i].Nombre
                            + ' ' + label
                        }
                        key={list.clases[i].idClase}
                        groupID={list.clases[i].idClase}
                        day={list.clases[i].dia_semana}
                        parentHandler={handler}
                        secondHandler={secondHandler}
                        allowDaysHandler={allowDaysHandler}
                        inQueueHandler={inQueueHandler}
                        dayFree={dayFree}

                    />
                )
            }
        }

    }
    return (
        <div id='clases'>
            {items}
        </div>
    );

}


//Calendar date picker for testing day
function tryDayPicker(dias, parentHandler, gen, loc, reset) {

    //Array to store excluded dates in 
    //MyCanlendar
    var allDates = []
    var excludedDates = []
    var days = Array.from(dias)

    //Sets the maxDate
    var maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + interval)

    allDates = eachDayOfInterval({
        start: new Date(),
        end: maxDate
    })

    var cond = false;
    var startDate = new Date(2023, 8, 11);

    for (const i in allDates)
        if (!days.includes(allDates[i].getDay()))
            excludedDates.push(allDates[i])
        else if (!cond) {
            startDate = allDates[i]
            cond = true;
        }

    if (!reset)
        return (
            <div>
                <MyCalendar
                    // start={startDate}
                    // minDate={startDate}
                    // start={new Date(2023,10,1)}
                    minDate={new Date(2023, 9, 1)}
                    // maxDate={new Date(2023,11,27)}
                    excludeDates={excludedDates}
                    parentHandler={parentHandler}
                    placeholderText="Día de inicio/prueba"
                    reset={reset}

                />
            </div>
        );
}

//Date picker for the born date
function bornDate(parentHandler, gen, loc) {

    var startDate = new Date(2003, 0, 1);
    return (
        <div>
            <MyCalendar
                start={startDate}
                maxDate={new Date(2017, 11, 31)}
                minDate={startDate}
                parentHandler={parentHandler}

                placeholderText="Fecha de nacimiento"
            />
        </div>
    );
}


export default genderSelect;
export { locationSelect, selectDayButton, bornDate };
export { groupsSelect, groupsSelect2 };
export { tryDayPicker };
export { groupSelectOptions }
