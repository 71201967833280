import React from 'react';

// Generates a custom select options in html
//function GenerateSelect(props){
class CustomSelect extends React.Component {

    constructor(props){
        super(props);
        //this.data = this.props.data;

        //Define class states
        //Value = select option value
        this.state = {
            value : '',
            data : this.props.data
        }

        this.handleChange = this.handleChange.bind(this);

    }

    //Works when a new option is selected
    //changing the state value
    handleChange(event) {

        //Sets the new state value
        const temp = event.target.value;
        this.setState({value: temp});

        //Calls the parent handler
        this.props.secondHandler(temp);
    }

    //Generate de options from props.data
    generateOptions() {
        var op = this.state.data.selecOptions.map((dat) => 
            <option 
                key={dat.value} 
                value={dat.value}
            > 
                {dat.label}
            </option>
        );

        return op
    }

    render() {

        return(
            <div className="SelectStyle">
            <select
                className="form-control "
                id={this.props.id} 
                value={this.state.value} 
                onChange={this.handleChange}
            >
                {this.generateOptions()}     
            </select>
            </div>
        );

    }
}

export default CustomSelect;