import React from 'react';

class MyTextArea extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            value: '',
            fieldName: this.props.id,
            //valid: false
        }

        this.handleChange = this.handleChange.bind(this);
    }

    
    handleChange(event) {
        this.setState({value: event.target.value});
        this.props.parentHandler({
            key: event.target.id, 
            value: event.target.value
        })
        //this.validate()
    }

    render() {
        return (
            <label>
                {this.props.label}
                <input 
                    type="text"
                    className="input100 bg-light rounded"
                    id={this.props.id}
                    key={this.props.quey}
                    value={this.state.value} 
                    onChange={this.handleChange}
                    
                />   
            </label>
        )
    }

}

export default MyTextArea;