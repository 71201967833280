import React from 'react';
/*
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
*/
import ReservationForm from './reservationForm.js'
//import Login from './admin/login'
//import Admin from './admin/admin'
import './App.css';
import './main.css';
import './util.css';

function App() {
  return (
   
          <ReservationForm />
     

       
  );
}

export default App;
