import React, {useState} from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("es",es);


//DatePicker for class reservations
function MyCalendar(props) {
    const [startDate, setStartDate] = useState( )

    function changeValue(date){
        setStartDate(date) 
        
        props.parentHandler(date)   

    }

        return (
            <div className="wrap-input100">
            <DatePicker 
                className="input100 bg-light rounded"
                dateFormat="dd/MM/yyyy"
                selected={startDate} 
                onChange={
                    date => changeValue(date)
                } 
                locale="es"
                minDate={props.minDate || new Date()}
                maxDate={props.maxDate}
                excludeDates={props.excludeDates}
                placeholderText={props.placeholderText}
                closeOnScroll={props.reset}
            />
            </div>

        );
}

export default MyCalendar;
