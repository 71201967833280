import React, { useState, useCallback } from 'react';
import './App.css';

//Imports form sections
import PartOne from './formComponents/partOne.js';
import PartTwo from './formComponents/partTwo.js';


//Other imports
import genderData,
{
    locationData,
    apiGetGrupos,
    apiPostReserva
}
    from './formComponents/formOptions';

class ReservationForm extends React.Component {

    constructor() {
        super();

        //Stores part one and part two form data
        this.state = {
            //Control fecth
            loaded: false,
            //Stores the groups data from request
            groups: [],
            //Part one values
            gender: genderData.selecOptions[0].value,
            location: locationData.selecOptions[0].value,
            //age: inefGAF.selecOptions[0].value,
            groupName: '',
            groupID: 6, //Hay que modificar esto sale 44 - partOne.js
            days: '',
            daysNames: '',
            startDay: '',
            bornDate: '',
            //Part two values
            name: '',
            surname: '',
            email: '',
            phone: '',
            //Controls form validation and fetch
            formValid: false,
            sended: false,
            queryStatus: 0,
            inQueue: false, //Hay que modifica esto
            //En realida lo que hay que hacer es añadir opcion nula por 
            //defecto
            resetDate: false


        }

        const formName = "reservationForm"
        var enviado = false

        this.handleData = this.handleData.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.ok = this.ok.bind(this)

    }


    componentDidMount() {

        const api = apiGetGrupos

        var myHeaders = new Headers();

        var miInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        //Fetch the query
        fetch(api, miInit)
            .then(response => response.json())
            .then(myJson => this.setState({
                loaded: true,
                groups: myJson.grupos,
                groupName: myJson.grupos[0].nombre
            })
            ).catch(err => {
                console.error(err);
            });


    }

    validateForm() {

        let erroCount = 0;
        let errorMsg = '';

        if (this.state.name.length < 2) {
            erroCount++;
            errorMsg = errorMsg + 'Nombre no válido\n ';
        }

        if (this.state.surname.length < 2) {
            erroCount++;
            errorMsg = errorMsg + 'Apellido no válido\n ';
        }

        if (!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            erroCount++;
            errorMsg = errorMsg + 'Email no válido\n ';
        }

        if (this.state.phone.length != 9) {
            erroCount++;
            errorMsg = errorMsg + 'Teléfono no válido \n\n ';
        }

        if (!this.state.startDay.match(
            /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/i
        ) && !this.state.inQueue) {
            erroCount++;
            errorMsg = errorMsg + 'Fecha de inixio no válida\n ';
        }

        if (!this.state.bornDate.match(
            /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/i
        )) {
            erroCount++;
            errorMsg = errorMsg + 'Fecha de nacimiento no válida\n ';
        }

        if (this.state.days.length < 3) {
            erroCount++;
            errorMsg = errorMsg + 'Selecciona horario\n ';
        }

        //Updates the state
        var temp = ''
        if (erroCount > 0) {
            //this.setState({formValid: false})
            temp = false;
            window.alert(errorMsg);
        } else {
            //this.setState({formValid: true})
            temp = true;

        }
        this.setState({ formValid: temp });
    }

    //Handlers updates data state
    handleData(data) {

        //Enable the form button
        switch (String(data.key)) {
            //Part one
            case "gender":
                this.setState({ gender: data.value })
                break;
            case "location":
                this.setState({ location: data.value })
                break;
            case "age":
                this.setState({ groupName: data.value })
                break;
            case "groupID":
                this.setState({ groupID: data.value })
                break
            case "days":
                this.setState({ days: data.value })

                break;
            case "daysNames":
                this.setState({ daysNames: data.value })

                break;
            case "startDay":
                this.setState({ startDay: data.value })
                break;
            case "bornDate":
                this.setState({ bornDate: data.value })
                break;
            case "name":
                this.setState({ name: data.value })
                break;
            case "surname":
                this.setState({ surname: data.value })
                break;
            case "email":
                this.setState({ email: data.value })
                break;
            case "phone":
                this.setState({ phone: data.value })
                break;
            case "inQueue":
                this.setState({ inQueue: data.value })
                break;
            case "resetDay":
                this.setState({ resetDay: data.value })
                break;
            default:
                break;

        }

    }


    //Submits the form
    handleSubmit(event) {
        event.preventDefault();
        const val = this.state.formValid;
        this.setState({ formValid: !val })

        if (val)
            this.setState({
                sended: true
            })
    }

    //This function is called on submit form
    ok() {

        let erroCount = 0;
        let errorMsg = '';

        if (this.state.name.length < 2) {
            erroCount++;
            errorMsg = errorMsg + 'Nombre no válido\n ';
        }

        if (this.state.surname.length < 2) {
            erroCount++;
            errorMsg = errorMsg + 'Apellido no válido\n ';
        }

        if (!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            erroCount++;
            errorMsg = errorMsg + 'Email no válido\n ';
        }

        if (this.state.phone.length != 9) {
            erroCount++;
            errorMsg = errorMsg + 'Teléfono no válido \n\n ';
        }

        // if (!this.state.startDay.match(
        //     /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/i
        // ) && !this.state.inQueue) {
        //     erroCount++;
        //     errorMsg = errorMsg + 'Fecha de inicio no válida\n ';
        // }

        if (!this.state.bornDate.match(
            /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/i
        )) {
            erroCount++;
            errorMsg = errorMsg + 'Fecha de nacimiento no válida\n ';
        }

        if (this.state.days.length < 3) {
            erroCount++;
            errorMsg = errorMsg + 'Selecciona horario\n ';
        }

        //Updates the state
        var temp = ''
        if (erroCount > 0) {
            window.alert(errorMsg);
        } else {
            let cosa;
            if (this.state.startDay.length <= 1)
                // cosa = 'ESPERA'
                cosa = '---'
            else
                cosa = this.state.startDay


            //Generates de Json
            const data =
                '{'
                + '"nombre": "' + this.state.name
                + '", "apellidos": "' + this.state.surname
                + '", "email": "' + this.state.email
                + '", "telefono": "' + this.state.phone
                + '", "fecha_nacimiento": "' + this.state.bornDate
                + '", "grupo": ' + this.state.groupID
                + ', "dia_prueba": "' + cosa
                + '", "clases": ' + this.state.days
                + ', "nombre_grupo": "' + this.state.groupName + ' '
                + this.state.location
                + '", "nombre_clases": "' + this.state.daysNames
                + '"}';


            const api = apiPostReserva;

            var myHeaders = new Headers();

            var miInit = {
                method: 'POST',
                //body: JSON.stringify(data), 
                body: data,
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
                cache: 'default'
            };


            fetch(api, miInit)
                .then(res => this.setState({
                    queryStatus: res.status
                }))
                .then(response => this.setState({
                    sended: true
                })
                )
                .catch(error => this.setState({
                    sended: false
                }));

        }
    }

    render() {
        return (
            <Test
                loade={this.state.loaded}
                listo={!this.state.sended}
                handler={this.ok}
                handleData={this.handleData}
                loaded={this.state.loaded}
                groups={this.state.groups}
                status={this.state.queryStatus}
            />
        )

    }

}


function Test(props) {
    const listo = props.listo;
    const loaded = props.loaded;
    const groups = props.groups;
    const status = props.status;

    if (loaded)
        if (listo)
            return (
                <div className="wrap-contact100">
                    <div
                        className="contact100-form validate-form text-center"
                    >
                        <div className='mb-5'>
                            <img src='terra-logo.svg' alt='Terra Sports Academy'></img>
                        </div>
                        <div id="part1" key="part1">
                            <PartOne
                                parentHandler={props.handleData}
                                loaded={loaded}
                                groups={props.groups}
                            />
                        </div>
                        <div id="part2" key="part2">
                            <PartTwo 
                                parentHandler={props.handleData}
                            />
                        </div>
                        <div id="part3">
                            <button
                                onClick={props.handler}
                                type="button"
                                className="btn btn-primary">Reservar</button>
                        </div>
                    </div>
                </div>


            )
        else {

            let message = ''
            let title = ''
            //If fetch OK, Checks Query status and generates message
            if (status == 201) {
                title = 'Reserva enviada'

                message = 'En breve recibirás un correo con toda '
                    + 'la información de tu reseserva. Si por algún '
                    + 'motivo no lo recibes no dudes en ponerte en '
                    + 'contacto con nosotros.'
            } else {
                title = 'Upss...'

                message = 'Lo sentimos, ha ocurrido un error '
                    + 'al realizar tu reserva. Por favor ponte en contacto'
                    + 'con nosotros en info@ximnasiatempo.es'
            }

            return (

                <div className="wrap-contact100">
                    <span className="contact100-form-title">
                        {title}
                    </span>
                    <h4>
                        {message}
                    </h4>
                </div>
            )
        }
    else
        return ('Cargando Formulario...')
}

export default ReservationForm;