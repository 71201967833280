import React from 'react';


// Generates a custom select options in html
class CustomClassSelect2 extends React.Component {


    constructor(props) {
        super(props);
        //this.data = this.props.data;

        //Define class states
        //Value = select option value
        this.state = {
            value: '',
            data: this.props.data,
            renderKey: 0
        }
        this.handleChange = this.handleChange.bind(this);
    }

    selectRef = null;

    //Works when a new option is selected
    //changing the state value
    handleChange(event) {

        //Sets the new state value
        //const temp = event.target.value;
        this.setState({ value: event.target.value });
        this.setState({ data: this.props.data })

        const i = event.target.options.selectedIndex;

        const gid = this.props.data[i].idGrupo

        //Calls the parent handler
        this.props.secondHandler(event.target, gid);

    }

    //Generate de options from props.data
    generateOptions() {
        const op = this.props.data.map((dat, i) =>
            <option
                key={i}
                id={i}
                name={dat.groupID}
                value={dat.groupID}
            >
                {dat.nombre}
            </option>
        );

        return op
    }

    componentDidUpdate(prevProps) {
        if (prevProps.index != this.props.index) {
            this.setState({ value: '', data: this.props.data});
        }
    }

    render() {
        return (
            <div className="SelectStyle">
                <select
                    className="form-control"
                    id={this.props.id}
                    value={this.state.value}
                    onChange={this.handleChange}
                >
                    {this.generateOptions()}
                </select>

            </div>
        );

    }
}

export default CustomClassSelect2;