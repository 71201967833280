import React, { useState }  from 'react';

class DayButton extends React.Component {

    constructor(props){
        super(props);
        
        const dayOfWeek = props.day;

        this.state = {
            val: props.value,
            groupID: props.groupID,
            dayOfWeek: props.day,
            //añadir plazas al estado para que las elimine
            clicked: false,
            style: 'btn btn-primary'
        }

        this.clickHandler = this.clickHandler.bind(this);
    }

    

    clickHandler(){
        var myStyle = this.state.style === 
                'btn btn-danger' ? 'btn btn-primary' : 'btn btn-danger'
        var cond = !this.state.clicked
         
        //Changes state clicked and color
        this.setState({
            clicked:  cond,
            style: myStyle
        })
       
        //Calls the partent handler
       this.props.parentHandler(this.state.groupID)
       this.props.secondHandler(this.state.val)
       if(this.props.dayFree) 
            this.props.allowDaysHandler(this.state.dayOfWeek)
        this.props.inQueueHandler(!this.props.dayFree)
            

    }

    render(){
        return (
            <div className="wrap-input100" key={this.key}>
            <button 
                type='button'
                className={this.state.style}
                //style={{background: this.state.color}}
                //Cambia el estado segun se pulse el boton
                onClick={this.clickHandler}
            >
                    {this.state.val} 
            </button>
            </div>
        );  
        
        

    }

}

export default DayButton;